<template>
  <div>
    <v-card dark flat tile :color="constants.COLOR_PRIMARY">
      <v-container>
        <v-row class="d-flex align-center pt-8 pb-8">
          <v-col>
            <h2 class="text-h2 font-weight-bold">
              Seja bem-vindo ao <br>
              Grupo Manatus
            </h2>
            <h2 class="text-h4">Como podemos ajudar você hoje?</h2>
            <v-btn
              dark
              class="mt-8"
              elevation="2"
              :color="constants.COLOR_SUCCESS">
              Assinar
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-parallax src="@/assets/img/intro1.jpg" height="500">
            </v-parallax>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container>
      <v-row class="pt-16 pb-16 text-center">
        <v-col>
          <h2>Soluções em Tecnologia da Informação</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis tortor quis metus imperdiet, eget rutrum lacus blandit. Integer pellentesque enim vitae est dapibus sodales
          </p>
          <v-btn
            class="mt-8"
            outlined
            :color="constants.COLOR_PRIMARY"
          >
            Saiba mais <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col>
          <h2>Soluções em Infraestrutura de...</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mattis tortor quis metus imperdiet, eget rutrum lacus blandit. Integer pellentesque enim vitae est dapibus sodales
          </p>
          <v-btn
            class="mt-8"
            outlined
            :color="constants.COLOR_PRIMARY"
          >
            Saiba mais <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-parallax src="@/assets/img/intro1.jpg">
        <v-layout column align-center justify-center>
          <h1>title</h1>
        </v-layout>
      </v-parallax>

    </v-container>
  </div>
</template>

<script>
import Constants from "@/constants/constants";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters([
      "stateLogin",
    ]),
  },
  data() {
    return {
      constants: Constants,
      vueAppApiResources: process.env.VUE_APP_API_RESOURCES,
    };
  },
  methods: {},
};
</script>
